import { Component, inject } from '@angular/core';
import { EventsService } from '../core/events.service';
import { Subscription } from 'rxjs';
import { Action, Events } from '../shared/types';
import { LoginComponent } from '../login/login.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [ LoginComponent ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {
  eventsService = inject(EventsService);
  router = inject(Router);
  navbarClass = 'navbar-container';
  subscription = new Subscription();

  ngOnInit() {
    this.subscription.add(
      this.eventsService.on(Events.CurrentActionChanged, (action: Action, sender: any) => {
        switch (action) {
          case Action.Stopped:
          case Action.Paused:
            this.navbarClass = 'navbar-container';
            break;
          case Action.Playing:
            this.navbarClass = 'navbar-container navbar-container-shrink';
            break;
        }
      })
    );
  }

  navigate(route: string) {
    this.router.navigate([route]);
  }
}
