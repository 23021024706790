import { Component, OnInit, Input, OnDestroy, inject } from '@angular/core';

import { Events } from '../../shared/types';
import { Subscription } from 'rxjs';
import { EventsService } from '../events.service';

@Component({
    selector: 'app-overlay',
    templateUrl: './overlay.component.html',
    styleUrls: ['./overlay.component.scss'],
    standalone: true
})
export class OverlayComponent implements OnInit, OnDestroy {

    subscription = new Subscription();
    enabled = false;
    queue: { url: string }[] = [];
    timerId = 0;
    timerHideId = 0;

    @Input() delay = 500;

    eventsService = inject(EventsService);

    ngOnInit() {
        // Handle request
        this.subscription.add(
            this.eventsService.on(Events.HttpRequest, ((data: any, sender: any) => {
                //See if data is already in the queue to avoid dups
                if (this.queue.find(d => d.url === data.url)) return;

                this.queue.push({url: data.url});
                if (this.queue.length === 1) {
                    // Only show if we have an item in the queue after the delay time
                    setTimeout(() => {
                        if (this.queue.length) { this.enabled = true; }
                    }, this.delay);
                }
            }))
        );

        // Handle response
        this.subscription.add(
            this.eventsService.on(Events.HttpResponse, ((data: any, sender: any) => {
                this.queue.pop();
                if (this.queue.length === 0) {
                    // Since we don't know if another XHR request will be made, pause before
                    // hiding the overlay. If another XHR request comes in then the overlay
                    // will stay visible which prevents a flicker
                    setTimeout(() => {
                        // Make sure queue is still 0 since a new XHR request may have come in
                        // while timer was running
                        if (this.queue.length === 0) { this.enabled = false; }
                    }, this.delay);
                }
            }))
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
