import { Component, inject } from '@angular/core';
import { environment } from '../../environments/environment'

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  year = new Date().getFullYear();
  appVersion = environment.app_version;
}
