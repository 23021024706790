import { Routes } from '@angular/router';
import { authGuard } from './core/auth.guard';

export const routes: Routes = [
    { path: '', redirectTo: 'start', pathMatch: 'full' },
    { 
        path: 'home', 
        loadComponent: () => import('./home/home.component').then(m => m.HomeComponent) 
    },
    { 
        path: 'start', 
        loadComponent: () => import('./start/start.component').then(m => m.StartComponent),
        canMatch: [authGuard]
    },
    {
        path: 'breathe',
        loadComponent: () => import('./breathe/breathe.component').then(m => m.BreatheComponent),
        canMatch: [authGuard]
    },
    {
        path: 'generator/:type',
        loadComponent: () => import('./generator/generator.component').then(m => m.GeneratorComponent),
        canMatch: [authGuard]
    },
    { 
        path: 'help', 
        loadComponent: () => import('./help/help.component').then(m => m.HelpComponent) 
    },
    { path: '**', redirectTo: 'home' }
];
