import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  clientPrincipal = null;
  window = inject(DOCUMENT).defaultView!;
  http = inject(HttpClient);
  private userPrincipalSubject = new BehaviorSubject(this.clientPrincipal);
  private isAuthenticatedSubject = new BehaviorSubject(false);
  userPrincipal$ = this.userPrincipalSubject.asObservable();
  isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

  constructor() {
    this.getUserPrinciple();
  }

  getUserPrinciple() {
    try {
      this.http.get('/.auth/me').subscribe((data: any) => {
        const { clientPrincipal } = data;
        this.clientPrincipal = clientPrincipal;
        this.userPrincipalSubject.next(this.clientPrincipal);
        this.isAuthenticatedSubject.next(this.clientPrincipal !== null);
        console.log('clientPrincipal', this.clientPrincipal);
        return this.clientPrincipal;
      });
    }
    catch (err) {
      this.userPrincipalSubject.next(null);
      this.isAuthenticatedSubject.next(false);
    }
    return null;
  }

  login() {
    this.window.location.href = '/login';
  }

  logout() {
    this.window.location.href = '/logout';
  }
}
