import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { OverlayComponent } from './core/overlay/overlay.component';
import { SettingsComponent } from './settings/settings.component';
import { IconService } from './core/icons.service';
import { BannerComponent } from './banner/banner.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [ FooterComponent, BannerComponent, NavbarComponent, OverlayComponent, CommonModule, RouterOutlet, SettingsComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  iconsService = inject(IconService);
  router = inject(Router);
}
