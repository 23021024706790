<app-overlay [delay]="300">
    <span class="spinner"></span>&nbsp;&nbsp;&nbsp;Working...
</app-overlay>
<app-navbar></app-navbar>
@if (router.url !== '/breathe') {
    <app-banner></app-banner>
}
<div [ngClass]="{'main-container': router.url !== '/breathe'}">
    <router-outlet></router-outlet>
</div>
<app-footer></app-footer>