export interface EventsData {
    event: Events;
    data: any;
    sender: any;
}

export type File = { name: string, url: string };

export enum Action {
    // Important to start at 1 since "if" statements evaluate 0 as false
    Playing = 1,
    Paused = 2,
    Stopped = 3
}

export type LookupData = {
    [key: string]: any;
    affirmations: File[] | null,
    backgroundImages: File[] | null,
    backgroundThumbnailImages: File[] | null,
    backgroundMusicFiles: File[] | null,
    languages: Language[] | null,
    meditations: File[] | null,
    breathingExercises: BreathingExercise[] | null
}

export type BreathingExercise = {
    id: string,
    name: string,
    benefits: string,
    description: string,
    image: string,
    data: BreathingExerciseData[]
}

export type BreathingExerciseTextPosition = 'center' | 'bottom';
export type BreathingExerciseType = 'box' | 'box-with-circle' | 'box-with-ball' | 'custom-4-7-8' | 'custom-5-5';
export type BreathingExerciseData = { seconds: number, scale: number, text: string };

export type Settings = {
    affirmationsAudioFile: File | null,
    backgroundImage: File | null, 
    backgroundMusicFile: File | null,
    breathingExerciseTextVisible: boolean,
    breathingExerciseTextPosition: BreathingExerciseTextPosition,
    breathingExerciseType: BreathingExerciseType,
    meditationsAudioFile: File | null,
    timerMinutes: number,
    timerVisible: boolean
};

export type GeneratorResponse = {
    status: boolean,
    response: string[],
    error: string
};

export type FileData = {
    container: string,
    fileName: string,
    filePath: string,
    title: string,
    url: string
}

export type TextToSpeechResponse = { 
    status: boolean, 
    fileData: FileData, 
    error: string 
};

export type Voice = { 
    gender: string, 
    country: string, 
    name: string, 
    voiceName: string 
};

export type Language = { 
    name: string, 
    code: string, 
    voices: Voice[] 
};

export interface StoreState {
    [key: string]: any;
    settings: Settings | null;
    lookupData: LookupData | null;
}

export enum StoreActions {
    InitData = 'INIT_DATA',
    SetFromLocalStorage = 'SET_FROM_LOCAL_STORAGE',
    SetLookupData = 'SET_LOOKUP_DATA',
    SetSettings = 'SET_SETTINGS'
}

export enum Events {
    AudioCreated,
    BackgroundImageChanged,
    CloseSettingsExpansionPanel,
    CurrentRouteChanged,
    OpenSettingsExpansionPanel,
    TimerReset,
    TimerCompleted,
    CurrentActionChanged,
    SettingsChanged,
    HttpRequest,
    HttpResponse
}