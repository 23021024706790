import { Injectable, inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { AFFIRMATIONS_ICON, BACK_ICON, BLOCKED_ICON, DISABLED_BY_DEFAULT_ICON, 
    HELP_ICON, IMAGE_ICON, MEDITATIONS_ICON, MUSIC_NOTE_ICON, PAUSE_BUTTON_ICON, PLAY_BUTTON_ICON, 
    REPLAY_BUTTON_ICON, SETTINGS_ICON, STOP_BUTTON_ICON, TIMER_ICON, WIND_ICON } from '../shared/svg-icons';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  iconList = [ 
    { name: 'music-note', icon: MUSIC_NOTE_ICON },
    { name: 'play-button', icon: PLAY_BUTTON_ICON },
    { name: 'stop-button', icon: STOP_BUTTON_ICON },
    { name: 'pause-button', icon: PAUSE_BUTTON_ICON },
    { name: 'replay-button', icon: REPLAY_BUTTON_ICON },
    { name: 'image', icon: IMAGE_ICON },
    { name: 'blocked', icon: BLOCKED_ICON },
    { name: 'disabled-by-default', icon: DISABLED_BY_DEFAULT_ICON },
    { name: 'settings', icon: SETTINGS_ICON },
    { name: 'help', icon: HELP_ICON },
    { name: 'wind', icon: WIND_ICON },
    { name: 'affirmations', icon: AFFIRMATIONS_ICON },
    { name: 'timer', icon: TIMER_ICON },
    { name: 'back', icon: BACK_ICON },
    { name: 'meditations', icon: MEDITATIONS_ICON }
  ];
  matIconRegistry = inject(MatIconRegistry);
  domSanitizer = inject(DomSanitizer);

  constructor() {
    this.iconList.forEach(item => {
        this.matIconRegistry.addSvgIconLiteral(item.name, this.domSanitizer.bypassSecurityTrustHtml(item.icon));
      });
  }
}
