import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { Events, EventsData } from "../shared/types";

@Injectable({
    providedIn: "root"
})
export class EventsService {
    private eventsSubject$ = new BehaviorSubject<EventsData>({} as EventsData);
    private events$ = this.eventsSubject$.asObservable();

    emit(event: Events, data: any, sender: any) {
        this.eventsSubject$.next({ event, data, sender });
    }

    on(event: Events, action: (data: any, sender: any) => void) : Subscription {
        return this.events$.pipe(
            filter((eventsData: EventsData) => {
                return eventsData.event === event;
            })
        )
        .subscribe((eventsData: EventsData) => {
            action(eventsData.data, eventsData.sender);
        });
    }

    emitCurrentAction(action: number, sender: any) {
        this.emit(Events.CurrentActionChanged, action, sender);
    }
}
