import { Component, inject } from '@angular/core';
import { SettingsComponent } from '../settings/settings.component';
import { AuthService } from '../core/auth.service';
import { Events } from '../shared/types';
import { Subscription } from 'rxjs';
import { Action } from '../shared/types';
import { EventsService } from '../core/events.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-banner',
  standalone: true,
  imports: [SettingsComponent],
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {
  authService = inject(AuthService);
  eventsService = inject(EventsService);
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);
  headerClass = 'header-container';
  isBreatheRoute = false;
  subscription = new Subscription();

  ngOnInit() {
    this.subscription.add(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.isBreatheRoute = event.url === '/breathe';
        }
      })
    );

    this.subscription.add(
      this.eventsService.on(Events.CurrentActionChanged, (action: Action, sender: any) => {
        switch (action) {
          case Action.Stopped:
          case Action.Paused:
            this.headerClass = 'header-container';
            break;
          case Action.Playing:
            this.headerClass = 'header-container header-container-shrink';
            break;
        }
      })
    );
  }

  navigate(route: string) {
    this.router.navigate([route]);
  }

}
